import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    default: '',
    warning: 'Oops..',
    danger: 'Oops..',
    success: 'Great!',
    compareTooltip: 'Include another metric for comparison',
    compareStatsTitle: 'Compare {title} with other metrics',
    noteCardTitle: 'Note for {date} under {subject}',
    metricGroups: {
      bodyComposition: 'Body composition',
      sleep: 'Sleep',
      nutrition: 'Nutrition',
      activity: 'Activity',
      emotion: 'Wellbeing',
    },
    providers: {
      manual: 'Manual input',
      FITBIT: 'Fitbit',
      GARMIN: 'Garmin',
      MYFITNESSPAL: 'Myfitnesspal',
      POLAR: 'Polar',
      OURA: 'Oura',
      WITHINGS: 'Withings',
      APPLE: 'Apple',
      WHOOP: 'Whoop',
      FATSECRET: 'FatSecret',
      INBODY: 'Inbody',
      GOOGLE: 'Google fit',
      VIRTUAGYM: 'Virtuagym',
      CRONOMETER: 'Cronometer',
      MEDICAVISIE: 'Medicavisie',
    },
    metrics: {
      physicalReadiness: 'Physical readiness',
      mentalReadiness: 'Mental readiness',
      howDoYouFeel: 'How do you feel?',
      howDidYouSleep: 'How did you sleep?',
      wasTheWorkoutHard: 'How was the workout?',
      steps: '# of steps',
      activeMinutes: 'Active minutes',
      averageHeartrate: 'Average heartrate',
      heartrateVariability: 'Heartreate variability',
      peakHeartRate: 'Peak heartrate',
      minHeartRate: 'Lowest heartrate',
      heartRateAtRest: 'Heartrate at rest',
      strain: 'Strain',
      recovery: 'Recovery',
      oxygenSaturation: 'Oxygen saturation',
      hoursOfSleep: 'Hours of sleep',
      hoursOfDeepSleep: 'Hours of deep sleep',
      hoursOfLightSleep: 'Hours of light sleep',
      hoursofREMSleep: 'Hours of REM sleep',
      hoursAwakeBeforeSleep: 'Hours awake in bed',
      timesWokenUp: 'Times woken up',
      bedTime: 'Bed time',
      wakeupTime: 'Wake-up time',
      caloriesConsumed: 'Calories consumed',
      amountOfProtein: 'Protein',
      amountOfCarbs: 'Carbs',
      amountOfFat: 'Fats',
      waterIntake: 'Water',
      weight: 'Bodyweight',
      fat: 'Bodyfat',
      muscle: 'Muscle mass',
      liquid: 'Body liquid',
      bones: 'Bone mass',
      deadlift: 'Deadlift',
      bench: 'Bench press',
      squat: 'Squat',
      respiratoryRate: 'Respiratory rate',
      metabolicAge: 'Metabolic age',
      visceralFat: 'Visceral fat',
      length: 'Length',
    },
    metricsWithUnit: {
      physicalReadiness: 'Physical readiness',
      mentalReadiness: 'Mental readiness',
      howDoYouFeel: 'How do you feel?',
      howDidYouSleep: 'How did you sleep?',
      wasTheWorkoutHard: 'How was the workout?',
      steps: '# of steps',
      activeMinutes: 'Active minutes',
      averageHeartrate: 'Average heartrate',
      heartrateVariability: 'Heartreate variability',
      peakHeartRate: 'Peak heartrate',
      minHeartRate: 'Lowest heartrate',
      heartRateAtRest: 'Heartrate at rest',
      strain: 'Strain',
      recovery: 'Recovery',
      oxygenSaturation: 'Oxygen saturation',
      hoursOfSleep: 'Hours of sleep',
      hoursOfDeepSleep: 'Hours of deep sleep',
      hoursOfLightSleep: 'Hours of light sleep',
      hoursofREMSleep: 'Hours of REM sleep',
      hoursAwakeBeforeSleep: 'Hours awake in bed',
      timesWokenUp: 'Times woken up',
      bedTime: 'Bed time',
      wakeupTime: 'Wake-up time',
      caloriesConsumed: 'Calories consumed',
      amountOfProtein: 'Protein (gr)',
      amountOfCarbs: 'Carbs (gr)',
      amountOfFat: 'Fats (gr)',
      waterIntake: 'Water (L)',
      weight: 'Bodyweight (kg)',
      fat: 'Bodyfat (kg)',
      muscle: 'Muscle mass (kg)',
      liquid: 'Body liquid (L)',
      bones: 'Bone mass (kg)',
      deadlift: 'Deadlift (kg)',
      bench: 'Bench press (kg)',
      squat: 'Squat (kg)',
      respiratoryRate: 'Respiratory rate (bpm)',
      metabolicAge: 'Metabolic age',
      visceralFat: 'Visceral fat',
      length: 'Length (cm)',
    },
    units: {
      physicalReadiness: '',
      mentalReadiness: '',
      howDoYouFeel: '',
      howDidYouSleep: '',
      wasTheWorkoutHard: '',
      steps: '#',
      activeMinutes: '',
      averageHeartrate: 'bpm',
      heartrateVariability: '',
      peakHeartRate: 'bpm',
      minHeartRate: 'bpm',
      heartRateAtRest: 'bpm',
      strain: '',
      recovery: '',
      oxygenSaturation: '%',
      hoursOfSleep: 'hours',
      hoursOfDeepSleep: 'hours',
      hoursOfLightSleep: 'hours',
      hoursofREMSleep: 'hours',
      hoursAwakeBeforeSleep: 'hours',
      timesWokenUp: '#',
      bedTime: 'hours',
      wakeupTime: 'hours',
      caloriesConsumed: 'kcal',
      amountOfProtein: 'gr',
      amountOfCarbs: 'gr',
      amountOfFat: 'gr',
      waterIntake: 'L',
      weight: 'kg',
      fat: 'kg',
      muscle: 'kg',
      liquid: 'L',
      bones: 'kg',
      deadlift: 'kg',
      bench: 'kg',
      squat: 'kg',
      respiratoryRate: 'bpm',
      metabolicAge: '',
      visceralFat: '',
      length: 'cm',
    },
    cockpit: {
      metricTooltip: {
        howDoYouFeel: 'Average value over the selected period',
        howDidYouSleep: 'Average value over the selected period',
        wasTheWorkoutHard: 'Average value over the selected period',
        steps: 'Average value over the selected period',
        activeMinutes: 'Average value over the selected period',
        averageHeartrate: 'Average value over the selected period',
        heartrateVariability: 'Average value over the selected period',
        peakHeartRate: 'Average value over the selected period',
        minHeartRate: 'Average value over the selected period',
        heartRateAtRest: 'Average value over the selected period',
        strain: 'Average value over the selected period',
        recovery: 'Average value over the selected period',
        oxygenSaturation: 'Average value over the selected period',
        hoursOfSleep: 'Average value over the selected period',
        hoursOfDeepSleep: 'Average value over the selected period',
        hoursOfLightSleep: 'Average value over the selected period',
        hoursofREMSleep: 'Average value over the selected period',
        hoursAwakeBeforeSleep: 'Average value over the selected period',
        timesWokenUp: 'Average value over the selected period',
        bedTime: 'Average value over the selected period',
        wakeupTime: 'Average value over the selected period',
        caloriesConsumed: 'Average value over the selected period',
        amountOfProtein: 'Average value over the selected period',
        amountOfCarbs: 'Average value over the selected period',
        amountOfFat: 'Average value over the selected period',
        waterIntake: 'Average value over the selected period',
        weight: 'Average value over the selected period',
        fat: 'Average value over the selected period',
        muscle: 'Average value over the selected period',
        liquid: 'Average value over the selected period',
        bones: 'Average value over the selected period',
        metabolicAge: 'Average value over the selected period',
        visceralFat: 'Average value over the selected period',
        length: 'Average value over the selected period',
      },
      targetTooltip: {
        howDoYouFeel: 'Desired goal',
        howDidYouSleep: 'Desired goal',
        wasTheWorkoutHard: 'Desired goal',
        steps: 'Desired goal',
        activeMinutes: 'Desired goal',
        averageHeartrate: 'Desired goal',
        heartrateVariability: 'Desired goal',
        peakHeartRate: 'Desired goal',
        heartRateAtRest: 'Desired goal',
        strain: 'Desired goal',
        recovery: 'Desired goal',
        oxygenSaturation: 'Desired goal',
        hoursOfSleep: 'Desired goal',
        hoursOfDeepSleep: 'Desired goal',
        hoursOfLightSleep: 'Desired goal',
        hoursofREMSleep: 'Desired goal',
        hoursAwakeBeforeSleep: 'Desired goal',
        timesWokenUp: 'Desired goal',
        bedTime: 'Desired goal',
        wakeupTime: 'Desired goal',
        caloriesConsumed: 'Desired goal',
        amountOfProtein: 'Desired goal',
        amountOfCarbs: 'Desired goal',
        amountOfFat: 'Desired goal',
        waterIntake: 'Desired goal',
        weight: 'Desired goal',
        fat: 'Desired goal',
        muscle: 'Desired goal',
        liquid: 'Desired goal',
        bones: 'Desired goal',
        metabolicAge: 'Desired goal',
        visceralFat: 'Desired goal',
        length: 'Desired goal',
      },
      badgeTooltip: {
        howDoYouFeel: 'Deviation from the desired goal',
        howDidYouSleep: 'Deviation from the desired goal',
        wasTheWorkoutHard: 'Deviation from the desired goal',
        steps: 'Deviation from the desired goal',
        activeMinutes: 'Deviation from the desired goal',
        averageHeartrate: 'Deviation from the desired goal',
        heartrateVariability: 'Deviation from the desired goal',
        peakHeartRate: 'Deviation from the desired goal',
        minHeartRate: 'Deviation from the desired goal',
        heartRateAtRest: 'Deviation from the desired goal',
        strain: 'Deviation from the desired goal',
        recovery: 'Deviation from the desired goal',
        oxygenSaturation: 'Deviation from the desired goal',
        hoursOfSleep: 'Deviation from the desired goal',
        hoursOfDeepSleep: 'Deviation from the desired goal',
        hoursOfLightSleep: 'Deviation from the desired goal',
        hoursofREMSleep: 'Deviation from the desired goal',
        hoursAwakeBeforeSleep: 'Deviation from the desired goal',
        timesWokenUp: 'Deviation from the desired goal',
        bedTime: 'Deviation from the desired goal',
        wakeupTime: 'Deviation from the desired goal',
        caloriesConsumed: 'Deviation from the desired goal',
        amountOfProtein: 'Deviation from the desired goal',
        amountOfCarbs: 'Deviation from the desired goal',
        amountOfFat: 'Deviation from the desired goal',
        waterIntake: 'Deviation from the desired goal',
        weight: 'Deviation from the desired goal',
        fat: 'Deviation from the desired goal',
        muscle: 'Deviation from the desired goal',
        liquid: 'Deviation from the desired goal',
        bones: 'Deviation from the desired goal',
        metabolicAge: 'Deviation from the desired goal',
        visceralFat: 'Deviation from the desired goal',
        length: 'Deviation from the desired goal',
      },
    },
    warnings: {
      sleepEntryNotEqual:
        'The light, deep and REM sleep is not equal to the hours of sleep',
    },
  },
};

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true,
});
